<template>
  <section :id="id" class="py-8 md:py-12 lg:py-16 relative print:py-4">
    <div :class="[!fullBleed ? 'container' : '', containerClass]">
      <div
        :class="[
          heroImage ? 'items-start md:w-1/2 md:pr-4 xl:pr-5 ' : '',
          fullBleed ? 'container' : '',
          contentClass
        ]"
      >
        <component
          :is="headingComponent"
          v-if="hasHeadingContent"
          class="mb-4 md:mb-6 xl:mb-8"
        >
          <slot name="subtitle">
            <span class="text-brand-grey-600 block">{{ subtitle }}</span>
          </slot>
          <span
            v-if="hasTitle"
            class="leading-relaxed"
            :class="[
              headingComponent,
              IsInternational
                ? `border-int-${colour}-02 text-[2rem] pb-1 border-b-4`
                : `border-brand-${colour}-300 border-b-5`
            ]"
          >
            <slot name="title">{{ title }}</slot>
          </span>
        </component>

        <p v-if="$slots.warning" class="font-bold text-lg">
          <slot name="warning" />
        </p>

        <div v-if="$slots.lead" class="sm:text-base mb-4">
          <slot name="lead" />
        </div>
      </div>
      <div v-if="heroImage" class="items-start md:w-1/2 md:pr-4 xl:pr-6">
        <slot />
      </div>
      <slot v-else />
    </div>

    <div
      v-if="heroImage"
      class="hidden md:block md:absolute md:w-1/2 md:pl-5 xl:pl-6 top-0 right-0 bottom-0"
    >
      <img :src="heroImage" class="object-cover w-full h-full" alt="" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentSection',
  props: {
    id: {
      type: String,
      default: ''
    },
    headingComponent: {
      type: String,
      default: 'h2'
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    heroImage: {
      type: String,
      default: ''
    },
    colour: {
      type: String,
      default: 'blue'
    },
    greyBg: {
      type: Boolean,
      default: false
    },
    fullBleed: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const IsInternational = inject('IsInternational', null)

    return { IsInternational }
  },
  computed: {
    hasTitle() {
      return !!this.title || !!this.$slots.title
    },
    hasSubtitle() {
      return !!this.subtitle || !!this.$slots.subtitle
    },
    hasHeadingContent() {
      return this.hasTitle || this.hasSubtitle
    }
  }
}
</script>
